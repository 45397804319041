import dubaiImage from "./images/location/hava.jpg"; // Replace with actual path
import { useState } from "react";
import axios from "axios";
import contact from "./images/location/contactban.jpg"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactPage = () => {
  
  const [formData, setFormData] = useState({name: '', email: '', contact: '', message: '', profile: 'personal'}); // State for form input

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const dataToSend = { ...formData, mode: 'email' };
    try {
      const response = await axios.post('https://versai.in/api/sendMail.php', dataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      // console.log('Response from server:',response.data.message);
      // console.log('Response from server:', response.data);
      toast.success("Application submitted successfully!");
    } catch (error) {
      // console.error('Error sending data:', error);
      toast.error("An error occurred. Please try again.");
    }
  };
  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      {" "}
      {/* Apply responsive padding */}
      {/* Breadcrumb Area */}
      <section
        className="relative overflow-hidden max-w-[120em] h-[23em] mx-auto text-center py-56 banner-6 bg-cover bg-center"
          style={{
            backgroundImage: `url(${contact})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            maxHeight:'70vh'
  }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-white to-white opacity-60 z-0"></div>
        <div className="relative z-10 text-gray-700">
          <div className="container mx-auto">
            <div className="flex justify-center items-center">
              <div className="space-y-6">
                <div className="fadeInUp">
                  <ul className="inline-flex space-x-2 text-lg">
                    <li>
                      <a href="tel:7597683262" className=" text-3xl text-gray-700">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="fadeInUp">
                  <h2 className="text-1xl font-semibold leading-relaxed">
                    Want To Contact Versai Tech Solutions?
                    <br />
                    Here are a few ways to get in touch with us.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Location Section */}
      <section className="contact-location py-16 px-3 md:px-11 bg-gray-50">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <span className="text-sky-600 text-lg uppercase">
              Our Company Locations
            </span>
          </div>
          <div className="flex flex-wrap justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
            {/* Dubai Location */}
            <div className="bg-white shadow-md rounded-lg p-6 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 fadeInUp">
              <img src={dubaiImage} alt="Jaipur" className="w-full mb-6" />
              <div className="text-center">
                <h4 className="text-2xl font-bold">Jhunjhunu</h4>
                <p className="text-gray-700 mt-2">
                  <i className="fas fa-map-marker-alt"></i> Ajitgarh
                </p>
                <a href="https://www.google.com/maps/place/Ajeetgarh,+Rajasthan/@27.9871257,75.1487652,14.53z/data=!4m15!1m8!3m7!1s0x39134e5b153ff44d:0x4a302317995baa8!2sRajasthan+333701!3b1!8m2!3d27.9852255!4d75.1837216!16s%2Fg%2F1hhw7pf7g!3m5!1s0x39134e17a8114355:0x279e86925c295dcf!8m2!3d27.9848195!4d75.1653506!16s%2Fg%2F12hl556rk?entry=ttu&g_ep=EgoyMDI0MTExNy4wIKXMDSoASAFQAw%3D%3D"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="inline-block mt-4 py-2 px-4 bg-sky-600 text-white rounded-full hover:bg-sky-700 transition"
                >
                  View on Map
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form */}
      <section className="contact-page px-8 md:px-16 py-16">
      <div className="container mx-auto">
      <div className="flex flex-wrap justify-center gap-10 xl:gap-20 items-start space-y-8 md:space-y-0 lg:space-y-8 ">
            {/* Form */}
            <div className="w-full lg:w-1/2 max-w-lg">
              <div className="text-left mb-8">
                <span className="text-sky-600 text-lg uppercase">
                  Contact Now
                </span>
                <h2 className="text-3xl font-bold mt-4">Let's Start With Us</h2>
                <p className="mt-6 mb-8">
                  Let us know about your requirements, and provide the following
                  details. We will get back to you with an idea of a brilliant
                  software.
                </p>
              </div>
              <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                 <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  onChange = {handleChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove numbers and special symbols
                  }}
                  className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                  required
                />
                    <input
                  type="email"
                  name="email"
                  onChange = {handleChange}
                  placeholder="Enter email"
                  pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                  title="Please enter a valid email address."
                  className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                  required
                  />
                </div>
                <div className="grid text-6 grid-cols-1 md:grid-cols-2 gap-6">
                   <input
                  type="text"
                  name="contact"
                  onChange = {handleChange}
                  placeholder="Enter contact number"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                  }}
                  className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                  required
                  />
                  <select
                    name="profile"
                    onChange = {handleChange}
                    className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4"
                    required
                  >
                    <option value="personal">Personal</option>
                    <option value="business">Business</option>
                  </select>
                </div>
                <textarea
                  name="message"
                  rows="5"
                  placeholder="Enter your message"
                  onChange={handleChange}
                  className="border-b-2 border-gray-300 focus:border-sky-600 py-4 px-4 w-full"
                  required
                ></textarea>
                <button
                  type="submit"
                  className="w-full py-3 bg-sky-600 text-white rounded-lg hover:bg-sky-700 transition"
                >
                  Submit
                </button>
              </form>
            </div>

            {/* Contact Details */}
            <div className="w-full  max-w-lg xl:w-1/3 ">
              <div className="bg-sky-100 p-6 rounded-lg shadow-lg">
                <div className="flex items-center mb-4">
                  <i className="fas fa-envelope text-sky-600 text-2xl mr-4"></i>
                  <div>
                    <span className="font-bold text-xl">Email:</span>
                    <p className="text-gray-700">
                      Our support team will get back to you within 24 business
                      hours.
                    </p>
                    <a
                      href="mailto:contact@versai.in"
                      className="text-sky-600 hover:underline"
                    >
                      contact@versai.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
