import PropTypes from "prop-types"; // Importing PropTypes
import poster from "./Images/Appseoo.png";

// App Component
const AppMarkting = () => {
  // Create a reusable Card component
  const Card = ({
    imgSrc,
    title,
    description,
    cardClass,
    imClass,
    teClass,
  }) => (
    <div className={`flex items-start mb-8 ${cardClass}`}>
      <div
        className={`w-36 h-12 flex items-center justify-center rounded-md mr-4 ${imClass}`}
      >
        <img src={imgSrc} alt={title} className="w-8 h-8" />
      </div>
      <div className={`${teClass}`}>
        <h2 className="text-xl font-bold text-gray-900">{title}</h2>
        <p className="text-gray-700 mt-2 text-lg">{description}</p>
      </div>
    </div>
  );

  // Prop types validation
  Card.propTypes = {
    imgSrc: PropTypes.string.isRequired, // imgSrc should be a string and required
    title: PropTypes.string.isRequired, // title should be a string and required
    description: PropTypes.string.isRequired, // description should be a string and required
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
        Our app marketing will <span className="text-pink-600">have</span>
      </h1>
      <p className="mb-10">
        Our comprehensive approach involves finding the target audience through
        research and streamlining the process with industry-approved methods for
        maximizing the outreach of App Marketing.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[minmax(300px,_fr)] gap-2">
        {/* Using Card component for each section */}
        <div className="md:w-[22em] lg:w-full">
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/download.png"
            title="Increased Downloads"
            description="Increasing the amount of downloads ultimately by optimizing the whole process of marketing the app."
          />

          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/star--v1.png"
            title="Improve App Rating"
            description="Obtaining great reviews and highest ratings by working on the feedbacks and overcoming all the imperfections."
          />
        </div>

        <img
          src={poster}
          alt="A group of people in a meeting room with a presentation on a screen"
          className="rounded-lg object-scale-down lg:row-span-2 row-start-1 md:col-start-2 w-full  lg:ml-0 md:w-80 lg:w-full h-full  z-0"
        />
        <div>
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/google-play.png"
            title="Google Play Store Optimization"
            description="Optimizing Google play store through standard practices and deployment of ASO tricks with astuteness."
          />
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/megaphone.png"
            title="Advertising and maintaining the interest"
            description="Building a steady propaganda about the app by consistent advertising and focusing on target audiences till the app is a sure success."
            teClass="ml-3"
          />
        </div>
        <div>
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/feedback.png"
            title="Application Reviews and Rating"
            description="Getting the most positive reviews and ratings by working constantly on the app development and countering glitches."
          />
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/apple.png"
            title="Apple Store Optimization"
            description="Optimizing Apple play store with the same effect and generating best results keeping with the sophistication of platform."
          />
        </div>
        <div className="md:col-span-2 col-span-1 lg:col-span-1 sm:flex justify-between  sm:gap-8 lg:gap-0 lg:flex-col">
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/edit.png"
            title="Application Content Optimization"
            description="Content optimization as per the platform specifications and projecting the app with brilliance and superiority."
            cardClass="lg:w-full"
          />
          <Card
            imgSrc="https://img.icons8.com/ios-filled/50/000000/identity-theft.png"
            title="Application Publisher Name"
            description="Maintaining authenticity and also utilizing it as a keyword, since many users search games by the publishers' name as well."
            cardClass=""
          />
        </div>
      </div>
    </div>
  );
};

export default AppMarkting;
