import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CareerPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    image: null,
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("contact", formData.contact);
    data.append("message", formData.message);
    data.append("image", formData.image);
    data.append("resume", formData.resume);
    data.append("mode", "form");

    try {
      const response = await axios.post("https://versai.in/api/sendMail.php", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Application submitted successfully!");
      // console.log("Response:", response.data);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      // console.error("Error:", error);
    }
  };

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      {/* Breadcrumb Area */}
      <section
        className="relative overflow-hidden text-center py-24 md:py-36 banner-6 bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?fit=crop&w=1920&h=400')",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-white to-white opacity-85 z-0"></div>
        <div className="relative z-10 text-gray-700">
          <div className="container mx-auto">
            <div className="flex justify-center items-center">
              <div className="space-y-6">
                <div className="fadeInUp">
                  <ul className="inline-flex space-x-2 text-lg">
                    <li>
                      <p className="text-3xl text-gray-700">Careers</p>
                    </li>
                  </ul>
                </div>
                <div className="fadeInUp">
                  <h2 className="text-1xl font-semibold leading-relaxed">
                    Join Versai Tech Solutions
                    <br />
                    Submit your details to apply for open positions.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Career Form */}
      <section className="career-page px-8 md:px-11 py-8 md:py-16">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center gap-10 xl:gap-20 items-start space-y-8 md:space-y-0 lg:space-y-8 ">
            <div className="w-full xl:w-2/3 max-w-2xl">
              <div className="text-left mb-8">
                <span className="text-sky-600 text-lg uppercase">Apply Now</span>
                <h2 className="text-3xl font-bold mt-4">
                  Start Your Career With Us
                </h2>
                <p className="mt-6 mb-8">
                  Fill out the form below and attach the required documents to
                  apply for a position at Versai Tech Solutions.
                </p>
              </div>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                    }}
                    onChange={handleChange}
                    className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                    required
                  />
                  <input
                    type="text"
                    name="contact"
                    placeholder="Enter contact number"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    onChange={handleChange}
                    className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                    title="Please enter a valid email address."
                    onChange={handleChange}
                    className="border-b-2 border-gray-300 focus:border-sky-600 py-2 px-4 w-full"
                    required
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700 mb-1"
                      htmlFor="photo"
                    >
                      Passport Size Photo
                    </label>
                    <label className="cursor-pointer flex items-center justify-center border border-gray-300 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100">
                      <input
                        type="file"
                        name="image"
                        id="photo"
                        accept="image/*"
                        onChange={handleChange}
                        className="sr-only"
                        required
                      />
                      Choose File
                    </label>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium text-gray-700 mb-1"
                      htmlFor="resume"
                    >
                      Resume (PDF only)
                    </label>
                    <label className="cursor-pointer flex items-center justify-center border border-gray-300 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100">
                      <input
                        type="file"
                        name="resume"
                        id="resume"
                        accept=".pdf"
                        onChange={handleChange}
                        className="sr-only"
                        required
                      />
                      Choose File
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full py-3 bg-sky-600 text-white rounded-lg hover:bg-sky-700 transition"
                >
                  Submit Application
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CareerPage;
