import React from 'react';
import brandingIcon from './Images/icons/branding.svg';
import developmentIcon from './Images/icons/development.svg';
import appIcon from './Images/icons/app.svg';
import marketingIcon from './Images/icons/marketing.svg';
import dotBg2 from './Images/shape/dot-shape.png';
import support from './Images/icons/support24.svg';
import dilever from './Images/icons/del.jpeg';

const Services = () => {
  return (
    <section className="service-section relative overflow-hidden px-4 sm:px-12 md:px-24  py-16 sm:py-20 md:py-28 bg-white">
      <div className="container mx-auto z-20">
        {/* Heading */}
        <div className="flex justify-center">
          <div className="lg:w-2/3 text-center">
            <div className="common-heading">
              <span className="text-sm sm:text-base md:text-lg font-semibold uppercase tracking-wider text-pink-600">
                Services We’re Provided
              </span>
              <h2 className="mb-4 text-2xl sm:text-3xl md:text-4xl font-bold">
                Assured promise on web development service
              </h2>
            </div>
          </div>
        </div>

        {/* Service Cards */}
        <div className="mt-8 sm:mt-10 md:mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 justify-center">
          {/* Cards */}
          {[
            { img: brandingIcon, title: "Sophisticated and the best", desc: "We use the latest technology such as HTML 5, CSS3, and CakePHP3.X to develop your website to give it the latest features." },
            { img: developmentIcon, title: "Dedicated Team", desc: "A full-time dedicated team of experts along with a project manager will be assigned for timely delivery of your website." },
            { img: appIcon, title: "Avant-garde tools", desc: "Our developer team is equipped with avant-garde technology and tools to ensure operational perfection." },
            { img: marketingIcon, title: "Transparent approach", desc: "We always value transparency more than anything else. Hence, we go for the upfront open discussion." },
            { img: support, title: "24/7 Support", desc: "We have devised multi-modal support channels to address your concern and provide the necessary support even at odd hours." },
            { img: dilever, title: "Timely delivery", desc: "We value everyone’s time and understand that even a slight delay may cause backlogs. Hence, we never shy away from going the extra mile to deliver what we promise." },
          ].map((service, index) => (
            <div key={index} className="s-block relative z-20 bg-white/70  lg:bg-white p-4 sm:p-6 py-12 sm:py-14 rounded-lg shadow-md transition-transform ">
              <div className="s-card-icon mb-4 flex justify-center">
                <img
                  src={service.img}
                  alt={service.title}
                  className="w-20 h-20 sm:w-24 sm:h-24 object-cover"
                />
              </div>
              <h4 className="text-lg sm:text-xl font-semibold text-center mb-2 sm:mb-4">
                {service.title}
              </h4>
              <p className="text-center  text-sm sm:text-base text-[#736a8e]">
                {service.desc}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Background Shape */}
      <div
        className="absolute z-10 inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${dotBg2})` }}
      ></div>
    </section>
  );
};

export default Services;
