import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Fullteam from "./Fullteam";
import Whychoose from "./WhyChooseUs";
import client from "./aboutImage/client.png";
import Retention from "./aboutImage/retention.png";
import business from "./aboutImage/buisness.png";
import Support from "./aboutImage/support.png";

// StatisticsCard Component with Counting Animation
const StatisticsCard = ({ imgSrc, title, description, alt, endNumber }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: false }); // triggerOnce set to false to allow re-triggering

  useEffect(() => {
    if (inView) {
      let start = 0;
      const duration = 2000; // Animation duration in ms
      const increment = endNumber / (duration / 16); // Assuming 60fps

      const counter = setInterval(() => {
        start += increment;
        if (start >= endNumber) {
          clearInterval(counter);
          setCount(endNumber);
        } else {
          setCount(Math.ceil(start));
        }
      }, 16);

      // Cleanup function to clear interval on component unmount or reentering viewport
      return () => clearInterval(counter);
    }
  }, [inView, endNumber]);

  return (
    <div
    ref={ref}
    className="text-center p-6 tracking-widest bg-white shadow-lg rounded-lg transform transition-transform duration-300 hover:scale-105"
  >
    <img
      alt={alt}
      className="mx-auto mb-4"
      src={imgSrc}
      width="100"
      height="100"
    />
    <h3 className="text-xl font-bold text-gray-900">
      {endNumber === 100 ? "24/7" : `${count}%`}
    </h3>
    <p className="text-gray-500">{description}</p>
  </div>
  );
};

// HeroSection Component
const HeroSection = () => {
  return (
    <div className="relative h-[35em] flex w-full justify-center items-center ">
      <div
        className="absolute max-w-[120em] mx-auto inset-0 bg-cover bg-center"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1507525428034-b723cf961d3e?fit=crop&w=1920&h=400')",
          opacity: 0.3,
        }}
      ></div>
      <div className="relative z-10 text-center py-40">
        <h2 className="text-xl text-gray-600 font-semibold">ABOUT US</h2>
        <h1 className="text-xl font-bold text-gray-900">
          CONVERT CHALLENGING PROBLEMS INTO
          <span className="text-pink-600"> DIGITAL SOLUTIONS </span>
        </h1>
      </div>
    </div>
  );
};

// Main App Component
const AboutUsSection = () => {
  return (
    <>
      <section className="font-roboto">
        {/* Hero Section */}
        <HeroSection />

        {/* Statistics Section */}
        <div className="bg-white py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <StatisticsCard
                imgSrc={business}
                title="85% Business"
                description="Through Customer Referrals"
                alt="Magnifying glass over a document"
                endNumber={80}
              />
              <StatisticsCard
                imgSrc={client}
                title="95% Client"
                description="Retention Rate"
                alt="Two chat bubbles"
                endNumber={95}
              />
              <StatisticsCard
                imgSrc={Retention}
                title="88% Retention"
                description="Rate of Tech Leads"
                alt="Rocket launching from a computer screen"
                endNumber={90}
              />
              <StatisticsCard
                imgSrc={Support}
                title="24x7"
                description="Support"
                alt="Shield with a checkmark"
                endNumber={100}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Other Sections */}
      {/* <Teamintro /> */}
      {/* <Teamgrid /> */}
      <Fullteam />
      <Whychoose />
    </>
  );
};

export default AboutUsSection;
