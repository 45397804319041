import React from "react";
import { Link } from "react-router-dom";
import man1 from "./Images/man12.png";
import man2 from "./Images/man8.png";
import man3 from "./Images/man3.png";
import man4 from "./Images/man9.png";

import img1 from "./icons/app.svg";
import img2 from "./icons/development.svg";
import img3 from "./icons/ecommerce.svg";
import img4 from "./icons/seo.svg";
import img5 from "./icons/ads.png";
import img6 from "./icons/dig.png";
import map from "./Images/map.png";
import dotBg2 from "./icons/dot-shape.png";

import TechIcons from "./TechIcons";
import Success from "./Success";

const ServiceCard = ({ color, icon, title, description, link }) => {
  return (
    <div
  className={`relative w-full flex flex-col  items-center gap-0 justify-center 2xl:w-72 ${color} lg:p-5 pt-5 lg:h-48 overflow-hidden rounded-lg shadow-lg cursor-pointer group`}
>
  <div className="w-14  h-10 sm:w-20 sm:h-20 transition-transform duration-500 ease-in-out transform lg:group-hover:scale-0 lg:group-hover:-translate-y-5">
    <img src={icon} alt={title} />
  </div>
  <div className="lg:absolute sm:text-center bottom-0   lg:bottom-20 left-0 right-0 bg-opacity-80 p-4 transition-transform duration-700 ease-in-out transform translate-y-0 lg:translate-y-full lg:group-hover:translate-y-20">
    <h3 className="text-2xl sm:text-xl font-bold mb-2">{title}</h3>
    <p className="text-sm opacity-100 lg:opacity-0 lg:group-hover:opacity-100 duration-700">
      {description}
    </p>
    <Link to={link}>
      <button
        className="text-sm font-bold mt-3 cursor-pointer sm:bg-blue-900 sm:text-white sm:p-2 rounded-lg"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        Read more &#x27A4;
      </button>
    </Link>
  </div>
</div>

  );
};

function Hero() {
  return (
    <div className="">
      <div
        className="md:pt-24 py-20 px-2 bg-[#e2eff7] "
        style={{ backgroundImage: `url(${dotBg2})` }}
      >
        <div
          className="max-w-[120em]  mx-auto  rounded-xl h-[500px] md:h-[600px] flex flex-col items-center justify-center text-center relative"
          style={{
            backgroundImage: `url(${map}), linear-gradient(to top, #16325B, #227B94)`,
            backgroundSize: "cover", // Adjust how the image is sized
            backgroundPosition: "center", // Center the image
            backgroundBlendMode: "overlay", // Blend the image with the gradient
          }}
        >
          <img
            src={man1}
            alt="Web and app"
            className="h-56 z-0 absolute top-5 left-5 rounded-lg opacity-80 hidden xl:block"
          ></img>
          <img
            src={man2}
            alt="web app"
            className="h-48 z-0 absolute top-5  right-0 rounded-lg opacity-80 hidden xl:block"
          ></img>
          <img
            src={man3}
            alt="web developer"
            className="h-56 z-0 absolute bottom-5 right-5 rounded-lg opacity-80 hidden lg:block"
          ></img>
          <img
            src={man4}
            alt="Tech solutions"
            className="h-56 z-0 absolute bottom-5 left-5 rounded-lg opacity-80 hidden lg:block"
          ></img>

          <h1 className="text-2xl   z-10 md:text-5xl text-[#e9e8e4]">
            Transform Your{" "}
            <span className="text-[#6beb6b] font-bold">Business</span> <br />{" "}
            with Innovative Technologies!
          </h1>
          <button
            className="bg-[#F0F4F8] hover:bg-[#c2d9f0] text-[#16355D] font-bold py-2 md:py-3 mt-14  px-4 md:px-8 rounded-xl  "
            onClick={() => {
              const element = document.getElementById("myid");

              const mobileOffset = -70; // Example offset for mobile devices
              const desktopOffset = 5; // Example offset for desktop

              const offset =
                window.innerWidth < 768 ? mobileOffset : desktopOffset; // Detect mobile vs desktop

              const elementPosition =
                element.getBoundingClientRect().top + window.scrollY; // Get element's position
              const finalPosition = elementPosition - offset; // Adjust position by offset

              window.scrollTo({ top: finalPosition, behavior: "smooth" }); // Scroll to calculated position
            }}
          >
            Explore
          </button>
          <TechIcons />
        </div>
      </div>
      <section className="py-12 max-w-[120em] mx-auto  flex flex-col gap-14">
        <div className="flex flex-col items-center gap-6 ">
          <div className="flex flex-col  md:flex-row items-center text-3xl gap-2 z-10 uppercase font-bold font-sans text-center">
            <h1 className="text-blue-950">We Transform</h1>
            <h1 className="text-pink-900">Ideas into Solutions</h1>
          </div>

          <div className="md:text-center text-justify px-5 sm:px-10 lg:px-40 flex flex-col gap-4 text-md text-lg ">
            <p>
              At{" "}
              <span className="font-semibold text-green-900">
                Versai Tech Solutions
              </span>
              , we provide exceptional web development services to help your
              business succeed online. Our skilled developers create
              user-friendly websites and robust applications, ensuring an
              attractive and functional online presence tailored to your needs.
            </p>{" "}
            <p>
              We specialize in mobile app development for both Android and iOS
              platforms. With our expertise in the latest technologies, we build
              intuitive apps that engage users and enhance your brand, guiding
              you from concept to launch.
            </p>{" "}
            <p>
              Cross-platform development is crucial for reaching a broader
              audience. At{" "}
              <span className="font-semibold text-green-900">
                Versai Tech Solutions
              </span>
              <span id="myid"></span>, we create seamless applications that
              function across multiple platforms, helping you maximize your
              reach and drive growth in a competitive market.
            </p>
          </div>
        </div>





          <div className=" px-5 md:px-16 lg:px-22 max-w-[105em] mx-auto  items-center  grid  xl:grid-cols-3 gap-8  py-5">
 
  <div className="w-full xl:col-span-1 text-justify  xl:text-justify space-y-4">
    <h2 className="text-red-900">Technologies</h2>
    <h1 className="text-2xl md:text-4xl font-sans font-bold text-blue-950">
      Tech Services We <br /> Specialize In
    </h1>
    <p className="text-md">
      At Versai Tech Solutions, we specialize in delivering innovative
      tech services that empower your business. From web development to
      mobile app development and digital marketing, we provide tailored
      solutions that meet your specific needs.
    </p>
  </div>

 
  <div className="grid grid-cols-1  h-full  md:grid-cols-2 xl:grid-cols-3  lg:grid-cols-3 xl:col-span-2 gap-8 justify-items-center w-full">
    <ServiceCard 
      color="bg-sky-200"
      icon={img1}
      title="Mobile Application Development"
      description="Optimize your supply chain operations for efficiency and cost-effectiveness."
      link="/Android-Application-Development"
    />
    <ServiceCard
      color="bg-indigo-100"
      icon={img2}
      title="Website Development"
      description="Leverage the power of blockchain technology to build secure and transparent solutions."
      link="/Website-Development"
    />
    <ServiceCard
      color="bg-rose-100"
      icon={img3}
      title="Ecommerce Solutions"
      description="Build decentralized applications that are secure, transparent, and community-driven."
      link="/Ecommerce-Solutions"
    />
    <ServiceCard
      color="bg-green-100"
      icon={img4}
      title="Search Engine Optimization"
      description="Optimize your supply chain operations for efficiency and cost-effectiveness."
      link="/Search-Engine-Optimization"
    />
    <ServiceCard
      color="bg-orange-100"
      icon={img5}
      title="Ads (Social Media)"
      description="Leverage the power of blockchain technology to build secure and transparent solutions."
      link="/Search-Engine-Optimization"
    />
    <ServiceCard
      color="bg-purple-100"
      icon={img6}
      title="Digital Marketing"
      description="Build decentralized applications that are secure, transparent, and community-driven."
      link="/Search-Engine-Optimization"
    />
  </div>
</div>
      </section>
      <Success />
    </div>
  );
}

export default Hero;
