import bg1 from "./Images/Al-Qaytharah.png";
import bg2 from "./Images/bg2.png" ;

const cardData = [
  {
    img: bg1,
    category: "Construction",
    title: "Al Qaytharah’s Online Presence: Innovation Meets Excellence",
    description:
      "Versai Tech Solutions developed alqaytharah.com, showcasing Al Qaytharah’s construction expertise with sleek design, innovation, and user-friendly features.",
    details:
      "Modern website highlights services, 200+ projects, Dubai-based office, contact details, and Al Qaytharah's commitment to excellence.",
    className: "bg-[#eae9ee] px-4 py-6",
  },
  {
    img: bg2,
    category: "MOBILE APP",
    title: "Quit Mate: Transforming Lives, One Step at a Time",
    description:
      "Versai Tech Solutions developed Quit Mate, a user-friendly app helping users overcome smoking and alcohol addiction, promoting healthier lives.",
    details:
      "Quit Mate empowers users to quit smoking and drinking, offering personalized features and progress tracking for addiction-free living.",
    className: "bg-[#eae9ee] pt-16",
  },
  
];



const Card = ({ img, category, title, description, details, className }) => (
  <div className="bg-white border rounded-3xl p-3 flex flex-col gap-1 w-full h-full justify-between">
    {/* Use the passed `className` prop for the background */}
    <div className={`flex items-center rounded-xl px-4 py-2 md:py-0 w-full h-full min-h-[15em] overflow-hidden `}>
      <img
        src={img}
        alt={title}
        className={`w-full h-full rounded-lg object-scale-down ${className}`}
      />
    </div>
    <div className="md:min-h-[20em]">
      <a href="https://alqaytharah.com/"><h2 className="text-xs sm:text-sm md:text-base font-bold text-gray-500 mt-4 mb-2">
        {category}
      </h2></a>
      <h3 className="text-base sm:text-lg md:text-xl font-bold text-gray-900 mb-2">
        {title}
      </h3>
      <p className="text-sm sm:text-base text-justify text-[#6b6b75] mb-4 font-semibold">
        {description}
      </p>
      <p className="text-sm sm:text-base text-justify text-[#6b6b75] font-semibold">
        {details}
      </p>
    </div>
  </div>
);

const Success = () => (
  <div className="py-20  ">
    <div className="container  mx-auto lg:px-20 px-2 md:px-4 lg:py-8">
      <div className="text-left mb-8">
        <button className="bg-gray-200 text-[12px] font-bold text-gray-700 px-4 py-2 rounded-md mb-4">
          WHERE WE DO
        </button>
        <h1 className="text-4xl font-bold text-gray-900">Success Stories</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 w-full   gap-6">
        {cardData.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  </div>
);

export default Success;
