const FAQ = () => {
  return (
    <section className="bg-[#fff8f2] text-[rgb(106,108,168)] py-10">
      <div className="container mx-auto lg:px-28 px-6 max-w-screen-xl">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-center text-3xl lg:text-4xl text-black font-bold mb-4">
              Frequently Asked Questions
            </h2>
            <ol className="list-decimal pl-6 mb-4">
              <li className="font-bold">What is Versai Tech Solutions?</li>
            </ol>
            <p>
              Versai Tech Solutions is a strategic technology partner
              specializing in custom software development and staff
              augmentation. Established in 2024, we focus on delivering tailored
              solutions for startups and small to medium-sized enterprises
              globally. With experience across industries like healthcare,
              e-commerce, fintech, real estate, and proptech, we have
              successfully developed over 100 software products. Our goal is to
              help businesses grow, optimize operations, and improve customer
              satisfaction.
            </p>
            <br />

            <ol className="list-decimal pl-6 mb-4" start="2">
              <li className="font-bold">
                What industries does Versai Tech Solutions work with?
              </li>
            </ol>
            <p>
              Versai Tech Solutions has experience working with various
              industries, including healthcare, e-commerce, fintech, real
              estate, and proptech. Our expertise allows us to create customized
              solutions suited to the unique needs of businesses in these
              sectors.
            </p>
            <br />

            <ol className="list-decimal pl-6 mb-4" start="3">
              <li className="font-bold">How large is your team?</li>
            </ol>
            <p>
              Our team is composed of over 25 specialists with diverse expertise
              across a wide range of industries and product complexities. We
              have dedicated departments for software development, project
              management, quality assurance, business analytics, recruitment,
              human resources, as well as account and legal support, ensuring we
              cover every aspect of your project needs.
            </p>

            <br />

            <ol className="list-decimal pl-6 mb-4" start="4">
              <li className="font-bold">
                What are the advantages of working with Versai Tech Solutions?
              </li>
            </ol>
            <p>
              Partnering with Versai Tech Solutions offers several key
              advantages:
              <ul className="list-disc pl-8">
                <li>
                  <strong>Collaborative Teamwork:</strong> We value the
                  collective efforts of our team to drive success and
                  innovation.
                </li>
                <li>
                  <strong>Accountability:</strong> We take ownership of our
                  responsibilities, ensuring efficiency and creating growth
                  opportunities for all team members.
                </li>
                <li>
                  <strong>Results-Driven:</strong> Our focus is on delivering
                  tangible and measurable outcomes for our clients, ensuring
                  impact and satisfaction.
                </li>
                <li>
                  <strong>Ongoing Development:</strong> We prioritize continuous
                  self-improvement and team development, fostering a culture of
                  growth and learning.
                </li>
                <li>
                  <strong>Professional Audits:</strong> We conduct thorough
                  software audits to enhance performance, streamline operations,
                  and optimize user experience.
                </li>
                <li>
                  <strong>Market Intelligence:</strong> We provide actionable
                  insights on the latest industry trends, keeping you informed
                  and ahead of the curve.
                </li>
                <li>
                  <strong>Punctual and Cost-Effective Execution:</strong> We
                  ensure timely delivery of projects, with a scope and timing
                  deviation of no more than 10%.
                </li>
                <li>
                  <strong>Comprehensive Post-Release Support:</strong> Our
                  ongoing maintenance and Quality Assurance services ensure your
                  software continues to meet your needs and perform optimally.
                </li>
              </ul>
            </p>
            <br />

            <ol className="list-decimal pl-6 mb-4" start="5">
              <li className="font-bold">
                Does Versai Tech Solutions offer post-release support?
              </li>
            </ol>
            <p>
              Yes, we provide post-release software support, which includes
              ongoing maintenance and Quality Assurance services to keep your
              software running smoothly after launch.
            </p>
            <br />

            <ol className="list-decimal pl-6 mb-4" start="6">
              <li className="font-bold">
                How does Versai Tech Solutions ensure project delivery is on
                time and within budget?
              </li>
            </ol>
            <p>
              We are dedicated to delivering projects on time and within budget,
              with a maximum deviation of 10% in both timeline and scope. Our
              focus is on ensuring that your project remains on track and
              cost-effective throughout its lifecycle.
            </p>

            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
