import PropTypes from 'prop-types';  // Importing PropTypes
import poster from "./Images/socialposter.png";
import ads from "./Icons/ads.png"
import ab from "./Icons/ab.png"
// AppMarkting Component
const AppMarkting = () => {
  // Reusable Card component
  const Card = ({ imgSrc, title, description, cardClass, imClass, teClass }) => (
    <div className={`flex items-start mb-8 ${cardClass}`}>
      <div className= {`w-28 h-8 flex items-center justify-center rounded-md mr-4 ${imClass}`}>
        <img src={imgSrc} alt={title} className="w-8 h-8 " />
      </div>
      <div className={`${teClass}`}>
        <h2 className="text-xl font-bold text-gray-900">{title}</h2>
        <p className="text-gray-700 mt-2  text-lg">{description}</p>
      </div>
    </div>
  );

  // Prop types validation
  Card.propTypes = {
    imgSrc: PropTypes.string.isRequired,      // imgSrc should be a string and required
    title: PropTypes.string.isRequired,       // title should be a string and required
    description: PropTypes.string.isRequired, // description should be a string and required
    cardClass: PropTypes.string, 
    imClass: PropTypes.string,
    teClass: PropTypes.string,
  };

  return (
    <div className="max-w-6xl tracking-widest mx-auto p-8 ">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        Social Media{" "}
        <span className="text-pink-600">Ad Optimization Services</span>
      </h1>
      <p className="w-full text-justify  mb-10">
        Our social media ad services focus on maximizing your ad performance by
        using cutting-edge techniques in ad creation, targeting, and tracking.
        From Facebook to YouTube, we streamline the process to ensure higher
        conversions and ROI.
      </p>

      {/* Service Cards */}
      <div className="grid grid-cols-1 max-w-5xl md:grid-cols-2 gap-1">
        
          {/* Card component for each service */}
         
          <Card
            imgSrc="https://img.icons8.com/color/50/design.png"
            title="Ad Creation & Design"
            description="We design visually compelling ads that resonate with your target audience and capture their attention."
            imClass=""
            cardClass="md:w-[20em] row-start-2 md:row-start-1 lg:w-full"
            teClass=""
          />
           <img
            src={poster}
            alt="A team analyzing ad performance data"
            className="rounded-lg sm:row-span-1 lg:row-span-2 w-full  self-center "

          />
          <Card
            imgSrc="https://img.icons8.com/color/50/target.png"
            title="Audience Targeting"
            description="Using demographic, interest, and behavior targeting, we reach the audience most likely to engage with your brand."
            imClass=""
            teClass="md:pr-0 lg:pr-9"
          />
          <Card
            imgSrc="https://img.icons8.com/color/50/combo-chart.png"
            title="Ad Performance Analysis"
            description="We continually monitor ad performance to ensure optimal results, tweaking strategies based on data-driven insights."
            imClass=""
            teClass=""
          />
          <Card
            imgSrc="https://img.icons8.com/color/50/improvement.png"
            title="Conversion Rate Optimization"
            description="We maximize your ad spend by focusing on strategies that improve click-through rates and conversions."
            imClass=""
            teClass=""
          />
           <Card
            imgSrc={ab}
            title="A/B Testing"
            description="Through A/B testing, we determine the best-performing creatives and messaging for higher engagement."
            imClass=""
            teClass=""
          />
          <Card
            imgSrc={ads}
            title="Retargeting Ads"
            description="We implement retargeting strategies to capture lost leads and boost conversions by re-engaging interested users."
            imClass=""
            teClass=""
          />
          <Card
            imgSrc="https://cdn-icons-png.flaticon.com/512/1006/1006652.png"
            title="Cross-Platform Advertising"
            description="We run ads across multiple platforms, ensuring a cohesive and consistent brand experience for your audience."
            cardClass=" col-span-1 lg:col-span-2 w-full  lg:grid lg:grid-cols-12"
            imClass="flex  lg:w-[50px]"
            teClass="col-span-11 lg:-ml-6"
          />


       
      </div>
    </div>
  );
};

export default AppMarkting;
