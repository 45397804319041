const Refund = () => {
    return (
      <section className="bg-[#fff8f2] text-[rgb(106,108,168)] py-10">
        <div className="container mx-auto lg:px-28 px-6 max-w-screen-xl">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="text-center text-3xl lg:text-4xl text-black font-bold mb-4">Cancellation and Refunds</h2>
  
              <p>
                <strong>Cancellation and Refunds by the Company:</strong><br />
                Please note that there may be certain orders that we are unable to accept and must cancel. We reserve the right, at our sole discretion, to refuse or cancel any order for any reason, without any claims or liability to pay finance charges or interest on the amount. Some situations that may result in your order being canceled include but are not limited to inaccuracies or errors in Solutions or pricing information, technical or technological problems, or problems identified in relation to credit/debit fraud. We may also require additional verifications or information before accepting any order. We will contact you if all or any portion of your order is canceled or if additional information is required to accept your order. If your order is canceled by the Company after your credit/debit card has been charged, the said amount will be refunded to that credit/debit card account.
              </p><br />
              <p>
                <strong>Cancellation by You:</strong><br />
                You agree and acknowledge that unless stated otherwise, you are not entitled to cancel any orders made by you on this Website. In the event you subscribe to any Solutions, the same may be canceled by you one month prior to the provision of the Solutions; in such a case, you will be refunded the entire amount after deducting any bank charges that may have been applicable. Further, during a period between one month and 14 days from when the Solutions are to be provided, if there is a cancellation request received, we may at our discretion refund 50% of the amount, after deducting any bank charges that may have been applicable, to you. A period of 14 days before the Solutions to be provided will not entertain any request for cancellation by the Company.
              </p><br />
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Refund;
  