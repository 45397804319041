import React from "react";
import group from "./Teamimage/group.jpg";

const YourComponent = () => {
  return (
    <div className="bg-gray-50 ">
      <script src="https://cdn.tailwindcss.com"></script>
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="relative w-full md:w-1/2 mb-40 md:mb-32 flex justify-center">
            <div className="absolute top-0 left-0 w-48 h-48 bg-pink-100 rounded-full -z-10"></div>
            <div className="absolute top-0 left-0 w-48 h-48 bg-blue-100 rounded-full opacity-50 -z-20"></div>
            <div className="relative">
              <img
                alt="Group of people having a discussion in an office setting"
                className="rounded-lg shadow-lg mb-4"
                height="300"
                src="https://storage.googleapis.com/a1aa/image/jeZ4QLEd1JzEOKM12qMK5olmKFNLbnrRxz1hUvLiPXeWRfGnA.jpg"
                width="400"
              />
              <img
                alt="Group of people sitting on stairs and smiling"
                className="rounded-lg shadow-lg absolute bottom-10 left-20 sm:left-10 transform -translate-x-16 translate-y-44"
                height="300"
                src={group}
                width="300"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              We Work for
              <span className="text-pink-500"> Your Success</span>
            </h1>
            <p className="text-gray-700 text-justify mb-4">
              At <b>Versai Tech Solutions</b>, we specialize in delivering
              high-quality web and app solutions tailored to the dynamic needs
              of modern businesses. Our expertise lies in designing websites
              with cutting-edge technologies like React, ensuring swift
              rendering and seamless user experiences. We prioritize crafting
              innovative designs that are not only visually appealing but also
              highly efficient, helping businesses thrive in a competitive
              landscape.
            </p>
            <p className="text-gray-700 text-justify mb-4">
              Our team stays ahead of industry trends by leveraging the latest
              tools and best practices to create scalable and responsive
              solutions. We are committed to exceeding client expectations,
              offering sleek, user-friendly interfaces and robust backend
              functionalities. Each project we undertake is a perfect fusion of
              creativity and technology, aimed at optimizing performance and
              driving growth for your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
