import React from "react";
import logo from "./header_section/logo1.png";
import { Link } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ImFacebook2 } from "react-icons/im";
import { FaRedditSquare } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { BsFillThreadsFill } from "react-icons/bs";


const Footer = () => {
  return (
    <footer className="py-12  shadow-2xl bg-[#e2eff7] text-gray-700">
      <div className="container w-full mx-auto px-3">
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img
            src={logo}
            alt="Versai Tech Solutions Logo"
            className="w-40 h-auto"
          />
        </div>

        {/* Main Content */}
        <div className="grid gap-8 sm:gap-x-5  lg:gap-x-20 w-full mx-auto  md:grid-cols-5">
  {/* Left Section - Our Vision */}
  <div className=" xl:ps-5 md:col-span-2 text-center sm:text-left">
    <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
    <p className="md:text-justify xl:text-left">
      Versai Tech Solutions is a pioneer in providing comprehensive services
      in website development, app development, and digital marketing. We
      understand the ever-growing demands of today’s digital landscape and
      strive to deliver innovative solutions that drive sustained growth for
      enterprises across various industries.
    </p>
  </div>

  {/* Middle Section - Contact Us */}
  <div className="tracking-widest text-center  sm:text-left">
    <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
    <div className="flex flex-col items-center sm:items-start">
      <div className="flex items-center mb-2 gap-2">
        <ion-icon name="call"></ion-icon>
        <span className="font-bold">
          <a href="tel:7597683262" className="text-blue-500 hover:underline">
            Get in touch
          </a>
        </span>
      </div>
      <div className="flex items-center mb-2">
        <FaEnvelope className="mr-2" />
        <span className="font-bold">
          <a href="mailto:info@versai.in" className="text-blue-500 hover:underline">
            Mail us
          </a>
        </span>
      </div>
      <div className="flex items-center mb-2">
        <FaMapMarkerAlt className="mr-2" />
        <span className="font-bold">Address:</span>
      </div>
      <p>Ajitgarh,<br />Jhunjhunu,<br /><b>Rajasthan</b></p>
      <p className="font-bold">Pincode: 333701</p>
    </div>
  </div>

  {/* Right Section - Company Info */}
  <div className="tracking-widest text-center sm:text-left">
    <h2 className="text-2xl font-bold mb-4">Company</h2>
    <ul>
      <Link to="/About-us" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">About Us</li>
      </Link>
      <Link to="/Career" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">Career</li>
      </Link>
      <Link to="/Contact-us" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">Contact Us</li>
      </Link>
    </ul>
  </div>

  {/* Right Section - Support Info */}
  <div className="tracking-widest text-center sm:text-left">
    <h2 className="text-2xl font-bold  mb-4">Support</h2>
    <ul>
      <Link to="/Privacy-policy" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">Privacy Policy</li>
      </Link>
      <Link to="/Term-and-condition" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">Terms and Conditions</li>
      </Link>
      <Link to="/Refund" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">Cancellation and Refund</li>
      </Link>
      <Link to="/Faqs" onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
        <li className="mb-2 hover:text-gray-900">FAQ</li>
      </Link>
    </ul>
  </div>
</div>


        {/* Social Media Links */}
        <div className="flex justify-center mt-8">
          <a href="https://x.com/Versai_Tech" className="mx-2 text-2xl xl:text-3xl hover:text-gray-800 text-gray-500 ">
            <FaXTwitter />
          </a>
          <a href="https://www.instagram.com/versai.in/" className="mx-2 text-2xl xl:text-3xl text-gray-500 hover:text-[#ec0866]">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/versai-tech-solution/" className="mx-2 text-2xl xl:text-3xl text-gray-500 hover:text-[#1469c7]">
            <FaLinkedin />
          </a>
          <a href="https://www.facebook.com/people/Versai-In/pfbid02KbPhZsCUhP33689QmmcB9w2vRKmRmDAHYhj4rEMMvZyiY6aRQPY4oxWff5zkgEXWl/?mibextid=JRoKGi" className="mx-2  text-2xl xl:text-3xl text-gray-500 hover:text-[#1469c7]">
          <ImFacebook2 />
          </a>
          <a href="https://www.reddit.com/user/Versai-in" className="mx-2 text-2xl xl:text-3xl hover:text-orange-600 text-gray-500 ">
            <FaRedditSquare />
          </a>
          <a href="https://discord.com/invite/versai.in" className="mx-2 text-2xl xl:text-3xl text-gray-500 hover:text-[#6f08ec]">
            <FaDiscord />
          </a>
          <a href="https://www.pinterest.com/Versaitechsolutions" className="mx-2 text-2xl xl:text-3xl text-gray-500 hover:text-red-600">
            <FaPinterestSquare />
          </a>
          <a href="https://www.instagram.com/versai.in/" className="mx-2  text-2xl xl:text-3xl text-gray-500 hover:text-black">
          <BsFillThreadsFill />
          </a>
        </div>

        {/* Footer Bottom - Copyright */}
        <div className="text-center mt-8">
          <p className="text-gray-500">
            &copy; 2024{" "}
            <span className="text-orange-500">Versai Tech Solutions</span> All
            Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
