import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDesktop,
  faMobileAlt,
  faBuilding,
  faHeadset,
  faTachometerAlt,
  faCog
} from '@fortawesome/free-solid-svg-icons';

// Service data with icon mapping
const services = [
  {
    title: "Quick to respond (Desktop, Tablet, Mobile)",
    description:
      "Maintaining a stable performance across a wide range of devices and operating systems, as well as ensuring that the website is free of bugs or lag time.",
    icon: <FontAwesomeIcon icon={faDesktop} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // Desktop context
  },
  {
    title: "Ecommerce Apps for Mobile Devices",
    description:
      "Creating mobile apps with the same level of precision and efficiency across all platforms, including Android and iOS, is an important goal.",
    icon: <FontAwesomeIcon icon={faMobileAlt} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // Mobile apps
  },
  {
    title: "Ecommerce Enterprise Development",
    description:
      "Constructing an e-commerce company with a strategy based on providing customers with cutting-edge products and services.",
    icon: <FontAwesomeIcon icon={faBuilding} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // Enterprise/business context
  },
  {
    title: "Production of CRM Solutions",
    description:
      "CRM platforms or tools that can keep track of communications, complaints, and deliver quickly and accurately are essential.",
    icon: <FontAwesomeIcon icon={faHeadset} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // CRM (communication) context
  },
  {
    title: "Optimizing ECommerce Performance",
    description:
      "Several parameters can be tweaked to improve platform performance, and audits can be performed on a regular basis to catch any glitches or latency.",
    icon: <FontAwesomeIcon icon={faTachometerAlt} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // Performance optimization
  },
  {
    title: "Ecommerce Customization",
    description:
      "Customizing platforms to meet specific needs and ensuring that they are up to date with any and all modifications results in maximum optimization.",
    icon: <FontAwesomeIcon icon={faCog} className="h-6 w-6 pt-5 text-[#1e3a8a]" />, // Customization (Settings/gear)
  },
];

function QuickResponse() {
  return (
    <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-6">
      {services.map((service, index) => (
        <div key={index} className="bg-white shadow-lg rounded-lg p-6 flex items-start space-x-4">
          <div className="text-center -mt-2">{service.icon}</div> {/* Displaying the icon */}
          <div>
            <h3 className="text-xl text-left  font-bold text-gray-900 mb-2">
              {service.title}
            </h3>
            <p className="text-gray-600  text-justify">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default QuickResponse;
