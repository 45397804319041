import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faHome, 
  faPlane, 
  faGraduationCap, 
  faBus, 
  faCalendarAlt, 
  faShoppingCart, 
  faGamepad, 
  faHeartbeat, 
  faMoneyBillWave, 
  faUtensils, 
  faClock, 
  faShoppingBasket 
} from "@fortawesome/free-solid-svg-icons";

const IndustriesWeServe = () => {
  const industries = [
    { name: 'Real Estate App', icon: faHome },
    { name: 'Tour & Travels App', icon: faPlane },
    { name: 'Education App', icon: faGraduationCap },
    { name: 'Transport App', icon: faBus },
    { name: 'Event App', icon: faCalendarAlt },
    { name: 'E-Commerce App', icon: faShoppingCart },
    { name: 'Game App', icon: faGamepad },
    { name: 'Healthcare App', icon: faHeartbeat },
    { name: 'Finance App', icon: faMoneyBillWave },
    { name: 'Restaurant App', icon: faUtensils },
    { name: 'On-Demand App', icon: faClock },
    { name: 'Grocery App', icon: faShoppingBasket },
  ];

  return (
    <div className="bg-gradient-to-b from-white to-pink-100 px-4 font-nunito py-10 w-full flex flex-col items-center justify-center">
      <h2 className="text-pink-500 text-center text-xs sm:text-sm md:text-base lg:text-lg pl-3 md:pl-0">
        WE HAVE WORKED ACROSS MULTIPLE INDUSTRIES
      </h2>
      <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mt-2 text-center">
        Industries We Serve
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 mt-8 px-2 sm:px-4">
        {industries.map((industry, index) => (
          <div 
            key={index} 
            className="bg-white shadow-md rounded-lg p-3 sm:p-4 md:p-6 flex items-center hover:shadow-lg transition-shadow duration-200 ease-in-out"
          >
            <FontAwesomeIcon 
              icon={industry.icon} 
              className="text-blue-900 w-6 h-6 sm:w-8 sm:h-8 md:w-8 md:h-10  lg:h-12 mr-2" 
            />
            <span className="text-xs sm:text-sm md:text-base lg:text-lg font-semibold">
              {industry.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndustriesWeServe;
